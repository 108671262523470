@import "../1-globals/globals";
@import "../1-globals/scroll-animation";
@import "../1-globals/media-query";
@import "../0-helpers/visibility-helpers";

.project {
    background  : $darkBlue;
    width       : 100%;
    min-height  : 100vh;
    padding-top : 184px;

    .page {
        padding : $distanceFromTheEdge $distanceFromTheEdge 96px;
    }

    a {
        text-decoration : none;
    }

    .projects {
        display : flex;

        @include on(tablet-portrait) {
            display : block;
        }
    }
}

.suggested-section.page {
    padding : 0;

    h2 {
        margin    : 0;
        padding   : $distanceFromTheEdge;
        font-size : 1.2em;
        z-index   : 1;
        line-height: 1.4em;

        @include on(mobile) {
            font-size: .8em;
        }
    }
}

.project {
    .contact.page {
        padding : 0 $distanceFromTheEdge $distanceFromTheEdge;

        h2 {
            margin-top : 0;
            padding    : 44px 0 20px;
        }
    }
}

.project-details {

    .title {
        font-size      : 2.618em;
        font-family    : $boldFontStack;
        text-transform : uppercase;
        letter-spacing : .05em;
        margin         : 0 $distanceFromTheEdge 24px;
        opacity        : 1;
        color          : $white;

        @include on(small-device) {
            font-size : 2em;
        }
    }

    .button {
        margin : 32px 0;
    }

    .info {
        display       : flex;
        margin-bottom : 48px;

        @include on(mobile) {
            display : block;
        }

        .summary {
            width  : 420px;
            margin : 0 $distanceFromTheEdge 0;
            color  : $white;

            @include on(mobile) {
                width : auto;
            }

            p {
                opacity : .4;
            }

            .highlight {
                opacity     : .7;
                font-size   : 1.618em;
                line-height : 1.3em;
                font-family : $semiBoldFontStack;
            }

            a {
                color         : $lightBlue;
                border-bottom : 2px transparentize($lightBlue, .8) solid;

                &:hover {
                    border-bottom-color : $lightBlue;
                }
            }
        }

        .service-list {
            margin  : 0;
            padding : 0 $distanceFromTheEdge 26px;
            color   : $white_80p;
            width   : 300px;

            @include on(mobile) {
                width   : auto;
                padding : 26px $distanceFromTheEdge;
            }

            &:before {
                content        : 'Services Rendered';
                font-family    : $boldFontStack;
                text-transform : uppercase;
                letter-spacing : .05em;
                font-size      : .7em;
                margin         : 0 0 16px 0;
                color          : $white_40p;
                border-bottom  : 3px solid;
                padding        : 0 0 16px 0;
                display        : block;
            }

            ul {
                list-style : none;
                margin     : 0;
                padding    : 0;
            }
            li {
                font-size    : 1em;
                margin-right : 24px;

                @include on(mobile) {
                    display      : inline-block;
                    width        : 50%;
                    margin-right : 0;
                }

                @include on(small-device) {
                    display : block;
                    width   : 100%;
                }
            }
        }
    }
}

.media {
    @include animate-on-scroll;

    /* Image media */
    &.image {
        position : relative;

        img {
            display : block;
        }

        .notes {
            width     : 100%;
            bottom    : 0;
            padding   : 16px $distanceFromTheEdge;
            font-size : .85em;
            position  : absolute;
            color     : $white_50p;

            @include on(mobile) {
                @include h5bp-visuallyhidden;
                position   : relative;
                background : #000;
            }

            &:before {
                content       : '';
                width         : 40px;
                height        : 2px;
                background    : $white_10p;
                display       : block;
                margin-bottom : 16px;

                @include on(mobile) {
                    display : none;
                }
            }
        }
    }

    /* Text media */
    &.text {
        padding   : 24px $distanceFromTheEdge;
        font-size : 1.2em;

        .preamble {
            display        : block;
            font-size      : .618em;
            text-transform : uppercase;
            letter-spacing : .05em;
            margin-bottom  : 12px;
        }

        p {
            max-width   : 480px;
            font-size   : .85em;
            line-height : 1.618em;
        }

        p:last-of-type { margin-bottom : 0; }
    }

    /* Heading media */
    &.heading {
        padding        : 64px $distanceFromTheEdge;
        font-size      : 1.4em;
        font-family    : $boldFontStack;
        text-transform : uppercase;
        letter-spacing : .1em;
    }
}