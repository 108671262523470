
/*---------- Global Mixins ----------*/

/*
    For responsive definition
*/
@mixin on($media) {

    @if $media == large-display {
        @media all and (max-width : 1280px) { @content; }
    } @else if $media == laptop-monitor {
        @media all and (max-width : 1440px) { @content; }
    } @else if $media == laptop-monitor-small {
        @media all and (max-width : 1366px) { @content; }
    } @else if $media == tablet-landscape {
        @media all and (max-width : 1024px) { @content; }
    } @else if $media == tablet-portrait {
        @media all and (max-width : 851px) { @content; }
    } @else if $media == mobile {
        @media all and (max-width : 640px) { @content; }
    } @else if $media == mobile-landscape {
        @media all and (max-width : 850px) 
        and (max-height : 430px) { @content; }
    } @else if $media == iphone-landscape {
        @media all and (max-width : 660px) 
        and (max-height : 380px){ @content; }
    } @else if $media == small-device {
        @media all and (max-width : 480px) { @content; }
    } @else {
        @media all and (max-width : $media) { @content; }
    }

    /* 667 || 375 */ 
}