@import "../1-globals/globals";
@import "../1-globals/content-styling";
@import "../1-globals/scroll-animation";
@import "../1-globals/media-query";

$cardBaseHeight : 608px;

.projects > .card:first-of-type.-with-details:before {
    margin-top : 72px;
    
    @include on(mobile) {
        margin-top : 32px;
    }
}

.card {
    display             : flex;
    height              : calc(100vh - 20px);
    min-height          : $cardBaseHeight;
    position            : relative;
    width               : 100%;
    background-position : center;
    background-size     : cover;
    align-items         : baseline;

    &.-half {
        width : 50%;

        @include on(tablet-portrait) {
            width : 100%;
        }
    }

    &:hover {
        opacity     : .9;

        .card-info { 
            opacity     : 1; 
        }
    }

    .card-info-bg {
        display         : flex;
        height          : calc(100vh - 20px);
        min-height      : 608px;
        position        : relative;
        width           : 100%;
        background-color: rgba(14, 13, 13, 0);
        transition      : all 320ms ease-out;
    }

    &.-with-details {
        cursor : pointer;

        &:before {
            $size: 22px;

            display     : block;
            content     : '';
            width       : $size;
            height      : $size;
            background  : url(../images/feature-indicator.svg) no-repeat center;
            background-size: 100%;
            margin      : 32px;
            right       : 0;
            position    : absolute;
        }

        &:hover {
            .card-info { 
                opacity             : 1; 

                -webkit-transform   : translateY(-72px);
                -ms-transform       : translateY(-72px);
                -o-transform        : translateY(-72px);
                -moz-transform      : translateY(-72px);
                transform           : translateY(-72px);
            }

            .button {
                -webkit-transform   : translateY(-64px);
                -ms-transform       : translateY(-64px);
                -o-transform        : translateY(-64px);
                -moz-transform      : translateY(-64px);
                transform           : translateY(-64px);
            }
        }

        .card-info-bg {
            &:hover {
                background  : rgba(14, 13, 13, 0.3);        
            }
        }

        .card-info {
            -webkit-transition  : all 0.3s ease-in-out;
            -o-transition       : all 0.3s ease-in-out;
            -moz-transition     : all 0.3s ease-in-out;
            transition          : all 0.3s ease-in-out;
        }

        .icon-details {
            $size : 64px;
            $offset : $size / -2;
            transition : all 300ms ease-out;
            opacity    : 0;
            width      : $size;
            position   : absolute;
            top        : 50%;
            left       : 50%;
            margin     : $offset 0 0 $offset;
        }

        &:hover .icon-details {
            opacity : 1;
        }

        .button {
            padding     : 12px 18px;
            font-size   : .85em;
            margin      : 0 auto;
            width       : initial;
            position    : absolute;
            text-align  : center;
            bottom      : -32px;
            right       : initial;
            left        : 32px;  
            color       : #fff;
            border      : 3px solid #fff;
            opacity     : 0;

            -webkit-transition  : all 0.3s ease-in-out;
            -o-transition       : all 0.3s ease-in-out;
            -moz-transition     : all 0.3s ease-in-out;
            transition          : all 0.3s ease-in-out;

            @include on(mobile) {
                font-size   : .618em;
            } 

            &:hover {
                background-color: #fff;
                color           : #060a0e;
            }
        }

        &:hover .button {
            opacity         : 1;
        }
    }

    @include animate-on-scroll;
}

.card-info {
    margin     : auto 0 0 0;
    padding    : 24px $distanceFromTheEdge;
    opacity    : .4;
    transition : opacity 260ms ease-out;

    &:before { @include top-dashed; }

    .title {
        margin-right : $distanceFromTheEdge;
        font-family  : $semiBoldFontStack;
        display      : block;
        font-size    : 1.25em;
        line-height  : 2em;
    }

    .description {
        font-family : $semiBoldFontStack;
        font-size   : $baseFontSize;
        color       : $white_40p;
        display     : block;
        line-height : 1.4em;
    }
}

.device .card .card-info,
.device .card .button { opacity : 1; }

.device .card.-with-details .card-info { padding: 92px 32px; }
.device .card .button { bottom: 28px; }

.device .card {
    &:hover {
        .card-info { 
            -webkit-transform   : initial;
            -ms-transform       : initial;
            -o-transform        : initial;
            -moz-transform      : initial;
            transform           : initial;
        }

        .button {
            -webkit-transform   : initial;
            -ms-transform       : initial;
            -o-transform        : initial;
            -moz-transform      : initial;
            transform           : initial;
        }
    }
}