@import "../1-globals/globals";

.analytics {
    width : 100%;
    position : fixed;
    bottom: 0;
    z-index : 5000;

    background: #111;

    span {
        display : block;
        font-size: .86em;
        margin-bottom : 8px;
        font-family: $semiBoldFontStack;
        color: $white_60p;
    }

    .action-group {
        display : flex;
        padding: 16px;
    }

    .button {
        font-size: 12px;
        padding: 12px;
        width : auto;
        margin-right : 16px;
    }
}

.plotter {
    z-index : 1000;
    position : absolute;
}

.plot {
    width: 5px;
    height: 5px;
    border-radius: 100%;
    position : absolute;
    background: #fff;

    &.type__m {
        background: $white_60p;
    }
    &.type__c {
        background: $red;
        z-index : 100;
    }
}

.analytics-overlay {
    width : 100%;
    height: 100%;
    background: $black_40p;
    position : fixed;
    top: 0;
    left: 0;
    z-index : 900;
}