@import "../1-globals/globals";
@import "../1-globals/content-styling";
@import "../1-globals/media-query";

$maxWidth : 620px;
$pColor : transparentize(#98b2c1, .2);

%group-label {
    width     : 100%;
    font-size : 1.3em;
    @include style-as-topic;
}

.about {
    background     : $darkBlue;
    padding-bottom : 0;

    /* Heading */
    h1 {
        font-size      : 4.2em;
        text-transform : none;
        font-family    : $semiBoldFontStack;
        color          : $brightBlue;
        line-height    : 1.18em;

        @include on(mobile) {
            font-size   : 10vw;
            line-height : 1.3em;
            width: 50%;
        }
    }

    .about-header-links {
        display         : flex;
        justify-content : space-between;
        max-width       : 56ch;
        margin-bottom   : 86px;
        color           : #00A3FF;

        label {
            font-family   : 'sailec-regular';
            font-style    : normal;
            font-weight   : 500;
            font-size     : 2rem;
            line-height   : 1.25;
            /* text-decoration: underline #00A3FF;
            text-underline-offset: 10px;
            text-decoration-thickness: 4px; */
            border-bottom: 4px solid #00A3FF;
            padding-bottom: 4px;

            &:hover {
                cursor: pointer;
            }
        }

        @include on(laptop-monitor) {
           max-width     : 310px;
           margin-bottom : 86px; 

           label {
                font-size     : 1.2rem;
           }
        }

        @include on(laptop-monitor-small) {
            margin-bottom: 82px;
        }

        @include on(mobile-landscape) {
            margin-bottom: 76px;
            max-width: 274px;

            label {
                border-bottom: 3px solid #00A3FF;
                padding-bottom: 2px;
            }
        }

        @include on (mobile) {
            max-width: 252px;
            margin-bottom: 98px;

            label {
                font-size: 1rem;
                line-height: 1.25;
                border-bottom: 3px solid #00A3FF;
                padding-bottom: 2px;
            }
        }
    }

    a { text-decoration : none; }

    .cta {
        padding    : 64px 0;
        border-top : 4px $white_5p solid;
    }
}

/* tldr block */
.tldr {

    .about-intro {
        font-family: 'sailec-regular';
        font-style: normal;
        font-weight: normal;
        font-size: 3.5rem;
        line-height: 1.25;
        color: $white;
        margin-bottom: 88px;
        max-width: 30ch;

        &:before {
            content: attr(data-label);
            display: block;
            margin-bottom: 32px;
            font-family: 'sailec-regular';
            font-style: normal;
            font-weight: bold;
            font-size: 1.5rem;
            line-height: 1.25;
            color: $white;
        }

        @include on(laptop-monitor) {
            font-size: 2.5rem;
            margin-bottom: 90px;

            &:before {
                margin-bottom: 30px;
                font-size: 1.2rem;
            }
        }

        @include on(laptop-monitor-small) {
            font-size: 2rem;
            margin-bottom: 80px;
        }

        @include on(tablet-portrait) {
            font-size: 1.875rem;
            line-height: 1.66;
            max-width: unset;
        }

        @include on(mobile-landscape) {
            font-size: 1.25rem;
            margin-bottom: 40px;

            &:before {
                font-size: .75rem;
            }
        }

        @include on (mobile) {
            font-weight: 400;
            font-size: 1.25rem;
            line-height: 1.55;
            margin-bottom: 70px;
        }

        @include on(small-device) {
            line-height: 1.55;
        }
    }

    .about-sub {
        font-family: 'sailec-regular';
        font-style: normal;
        font-weight: normal;
        font-size: 1.5rem;
        line-height: 1.5;
        color: $white;
        margin-bottom: 64px;
        max-width: 54ch;

        > span {
            font-weight: bold;
            display: block;
        }

        @include on(laptop-monitor) {
            font-size: 1rem;
            max-width: 60ch;
        }

        @include on(tablet-portrait) {
            max-width: unset;
        }

        @include on(mobile-landscape) {
            font-size: .75rem;
        }

        @include on(mobile) {
            font-size: 1rem;
            line-height: 1.33;
            max-width: 100%;
        }
    }

    #service-extensions {

        text-transform: none;

        &:before {
            display: none;
        }

        @include on(laptop-monitor) {
            font-size: 1.2rem;
        }

        @include on(mobile) {
            margin-top: 64px;
        }
    }

    .service-paragraph {
        font-family: "sailec-regular";
        font-style: normal;
        font-weight: normal;
        font-size: 1.875rem;
        line-height: 1.25;
        color: $white;
        width: 100%;
        max-width: 1098px;
        max-width: 46ch;

        @include on(laptop-monitor) {
            font-size: 1.5rem;
            max-width: 46ch;
        }

        @include on(tablet-portrait) {
            font-size: 1.375rem;
            line-height: 1.81;
            max-width: unset;
        }

        @include on(mobile-landscape) {
            font-size: 0.875rem;
        }

        @include on(mobile) {
            font-size: 1.25rem;
            line-height: 1.6;
        }
    }

    .tab-group {
        .tabs {
            border-bottom: none;
            max-width: 114ch;
        }

        .panes {
            margin-bottom:  10px;

            @include on(mobile) {
                margin-bottom: 18px;
            }
        }

        .design-and-development-tab {
            > .tab-content-group {
                margin-top: 0;
                margin-bottom: 3em;
                padding-bottom: 3em;
                border-bottom: 1px solid #cecece40;

                
                > div {
                    > strong {
                        margin-top: 0;
                    }
                }
                

                &:last-of-type {
                    margin-bottom: 50px;
                    padding-bottom: 0;
                    border-bottom: none;
                }

                @include on(mobile) {
                    margin-bottom: 3.5em;
                }
            }
        }

        .tab-content-group {
            margin-top: 64px;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            max-width: 106ch;

            > div {
                > strong {
                    text-decoration: unset;
                }
            }

            > .design-dev-paragraph {
                > strong {
                    font-size: .875rem;
                    text-transform: unset;
                }

                > p {
                    margin-bottom: 0;
                }
            }

            @include tab-content-group-styles;

            @include on(laptop-monitor) {
                max-width: 92ch;

                > .design-dev-paragraph {
                    > strong {
                        font-size: 0.875rem;
                    }
                }
            }

            @include on(690px) {
                display: block;
            }

            @include on(mobile-landscape) {
                display: flex;
            }

            @include on(mobile) {
                max-width: 100%;

                > .design-dev-paragraph {
                    margin-bottom: 2em;

                    > strong {
                        font-size: 0.875rem;
                        text-transform: unset;
                    }

                    > p {
                        font-size: 0.875rem;
                    }

                    > p:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }


        .cta {
            border-top: none;

            @include on(mobile) {
                padding-top: 0;
                /* width: 120%; */
            }

            .button {
                font-family: "sailec-regular";
                font-style: normal;
                font-weight: bold;
                font-size: 1.5rem;
                line-height: 1.5;
                color: #00A3FF;

                border: 2px solid #00A3FF;
                box-sizing: border-box;
                padding: 16px 24px;
                text-transform: none;

                @include on(laptop-monitor) {
                    font-size: 1rem;
                }

                @include on(tablet-portrait) {
                    font-size: 0.875rem;
                }

                @include on(mobile) {
                    font-size: 0.75rem;
                    line-height: 3;
                    padding: 8px 12px;
                }
            }
        }
    }

    .tabs {
        justify-content: unset;

        > .tab {
            font-family: "sailec-regular";
            font-style: normal;
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 1.25;
            color: rgba(0, 163, 255, 0.4);
            box-shadow: none;
            border-bottom: 1px solid rgba(196, 196, 196, 0.2);
            flex: none;
            padding: 0 34px 10px 0;
            width: 100%;
            max-width: fit-content;

            @-moz-document url-prefix() {

                max-width: -moz-fit-content;
            }

            &:hover {
                box-shadow: none;
                color: rgba(0, 163, 255, 0.6);
                border-bottom: 1px solid rgba(0, 163, 255, 0.6);
            }

            &:last-of-type {
                box-shadow: none;
                padding-right: 5%;

                &:hover {
                    box-shadow: none;
                }
            }

            @include on(laptop-monitor) {
                font-size: 1rem;
            }

            @include on(tablet-portrait) {
                max-width: none;
            }

            @include on(mobile) {
                padding-bottom: 16px;
                margin-bottom: 16px;
            }
        }

        > .tab.-active {
            color: #00A3FF;
            box-shadow: none;
            border-bottom: 1px solid #00A3FF;

            &:last-of-type {
                box-shadow: none;

                &:hover {
                    box-shadow: none;
                }
            }

            &:hover {
                box-shadow: none;
            }
        }

        @include on(mobile-landscape) {
            flex-wrap: wrap;
        }
    }

    .consulting-tab, 
    .project-management-tab,
    .design-and-development-tab {
        .tab-content {
            font-family: "sailec-regular";
            font-style: normal;
            font-weight: normal;
            font-size: 1.25rem;
            line-height: 1.5;
            /* line-height: 2.25em; */
            color: #FFFFFF;
            margin-bottom: 32px;
            max-width: 60ch;

            strong {
                color: #FFFFFF;
                text-transform: none;
                margin: 0;
                font-size: 1.25rem;

                @include on(laptop-monitor) {
                    font-size: 1rem;
                }

                @include on(mobile-landscape) {
                    font-size: 0.875rem;
                }
            }

            @include on(laptop-monitor) {
                font-size: 1rem;
            }

            @include on(mobile-landscape) {
                font-size: 0.875rem;
                line-height: 1.5;
                margin-bottom: 20px;
                max-width: 80ch;
            }
        }

        .tab-group-list {
            display: list-item;
            /* justify-content: space-between; */
            margin-bottom: 64px;
            padding-bottom: 0;
            padding-left: 12px;

            li {
                
                font-family: "sailec-regular";
                font-style: normal;
                font-weight: bold;
                font-size: 0.75rem;
                line-height: 2;
                color: #FFFFFF;
                max-width: 25ch;
                /* text-align: center; */
                list-style: unset;

                span {
                    position: relative;
                    left: 10px;
                }

                @include on(laptop-monitor) {
                    line-height: 1.5;
                }

                @include on(tablet-portrait) {
                    max-width: 38%;
                }

                @include on(mobile) {
                    font-size: 0.625rem;
                    display: list-item;
                    /* white-space: nowrap; */
                    width: 50%;
                    max-width: 50%;
                    /* flex-direction: column; */
                    margin-right: 0;
                    text-align: unset;
                }
            }
            
            @include tab-group-content-images; 

            @include on(laptop-monitor) {
                max-width: 88ch;
            }

            @include on(mobile-landscape) {
                flex-wrap: wrap;
            }

            @include on(mobile) {
                margin-right: 0;
                flex-wrap: wrap;
                padding-left: 16px;
                margin-bottom: 64px;
            }
        }
    }

    hr {
        border-color: #FFFFFF;
        opacity: 0.2;
    }

    h2 {
        margin-top : 64px;

        &:before { margin-bottom : 32px; }

        + h3 {
            margin-top  : 0;
            padding-top : 0;
        }

        @include on(small-device) {
            margin-top : 48px;
        }
    }

    h3 {
        margin-top : 0;
    }

    ul {
        list-style : none;
        margin     : 0;
        padding    : 0 0 32px;
        display    : flex;
        flex-wrap  : wrap;
        max-width  : 1098px;
        justify-content: space-between;
        margin-bottom: 92px;
        max-width: 110ch;

        &:nth-of-type(-n+2) {
            /* border-bottom : 2px $white_5p solid; */
        }

        .experience, .expertise {

            font-family: 'sailec-regular';
            font-style: normal;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 1.5;
            color: $white;
            width: 400px;

            &:before {
                content: attr(data-label);
                display: block;
                font-family: 'sailec-regular';
                font-style: normal;
                font-weight: bold;
                font-size: 0.875rem;
                color: $white;
                opacity: 0.6;
                line-height: 1.25;
                margin-bottom: 38px;

                @include on(laptop-monitor) {
                    font-size: 0.75rem;
                    margin-bottom: 32px;
                }
            }

            @include on(laptop-monitor) {
                width: 358px;
            }
        }

        .experience {
            font-family: 'playfair-display';
            font-style: normal;
            font-weight: normal;
            font-size: 7.875rem;
            line-height: 1;

            &:before {
                margin-bottom: 0;
            }

            @include on(laptop-monitor) {
                font-size: 4rem;

                &:before {
                    margin-bottom: 14px;
                }
            }

            @include on(laptio-monitor-small) {
                font-size: 3.75rem;
            }

            @include on(tablet-portrait) {
                margin-bottom: 100px;
                font-size: 5rem;
                line-height: 0.88;
                width: 310px;
            }

            @include on(mobile-landscape) {
                font-size: 4rem;
                margin-bottom: 0;
            }
        }

        .expertise {
            @include on(laptop-monitor) {
                font-size: 0.625rem;
                max-width: 270px;
            }

            @include on(tablet-portrait){
                &:before {
                    margin-bottom: 20px;
                }
            }
        }

        &:last-of-type {
            li {
                /* width : 50%; */ 

                @include on(700px) {
                    width : 100%;
                }
            }
        }

        @include on(laptop-monitor) {
            max-width: 66ch;
            margin-bottom: 50px;
        }

        @include on(mobile-landscape) {
            flex-wrap: nowrap;
        }

        @include on(mobile) {
            margin: 0px 0 76px;

            .experience {
                line-height: 1.4em;
		        margin-bottom: 100px;
            }

            .expertise {
                line-height: 1.4em;
                font-size: 1.125rem;
                width: 100%;
                max-width: 100%;
                padding-right: 0;
                
                &:before {
                    margin-bottom: 30px;
                }
            }
        }

        @include on(small-device) {
            .experience {
            
                line-height: 1em;
                font-size: 4rem;
		        margin-bottom: 80px;
            }

            .expertise {
                font-size: 1rem;
            }
        }
    }

    li {
        /* width         : 33%; */
        line-height   : 1.4em;
        margin-bottom : 8px;
        padding       : 2px 16px 2px 0;
        color         : $white_80p;

        @include on(tablet-portrait) { width : 50%; padding-right : 24px; }
        @include on(small-device) { width : 100%; }

        em {
            margin      : 4px 0 0;
            font-style  : normal;
            display     : block;
            font-size   : .8em;
            color       : $white_35p;
            line-height : 1.2em;
        }
    }

    strong {
        @extend %group-label;
    }
}

.page .segment.clients {
    margin-bottom : 0;
    max-width: 88ch;

    @include on(laptop-monitor) {
        max-width: 80ch;
    }
}

.clients {
    font-family: "sailec-regular";

    #clients {

        font-size: 1.2rem;
        text-transform: none;

        &:before {
            display: none;
        }

        @include on(mobile-landscape) {
            // font-size: .75rem;
        }
    }

    img {
        width   : 100%;
        margin  : 96px 0;
        opacity : .8;
        display : block;

        @include on(small-device) {
            margin : 48px 0;
        }
    }

    p {
        /* max-width : 100% !important; */

        font-size: 1.875rem !important;
        line-height: 1.25 !important;
        max-width: 46ch !important;
        color: $white !important;

        @include on(laptop-monitor) {
            /* font-size: 1.5em !important; */
            font-size: 1.5rem !important;
            line-height: 1.25 !important;
            max-width: 48ch !important;
        }

        @include on(tablet-portrait) {
            font-size: 1.375rem !important;
            line-height: 1.81 !important;
        }

        @include on(mobile-landscape) {
            font-size: .875rem!important;
            max-width: 70ch !important;
        }

        @include on(mobile) {
            font-size: 1.25rem !important; 
            line-height: 1.6 !important;
        }
    }

    .client-groupings {
        margin-top    : 32px;
        margin-bottom : 64px;
        display       : flex;
        flex-wrap     : wrap;

        @include on(tablet-portrait) {
            margin-bottom : 0;
        }

        .group {
            width         : 50%;
            padding-right : 32px;
            margin-bottom : 0px;

            &:first-of-type {
                @include on(mobile) {
                    margin-top : 0;
                }
            }

            ul {
                display: block;
                margin-bottom : 0;

                li {

                    &:last-of-type {
                        margin-bottom: 0;
                    }

                    @include on(tablet-portrait) {
                        width: 100%;
                    }
                }

                @include on(laptop-monitor) {
                    font-size: 12px;
                }

                /* @include on(tablet-portrait) {
                    
                } */
            }

            @include on(tablet-portrait) {
                width : 50%;
                padding-right : 0;
            }
            @include on(mobile-landscape) {
                margin-bottom: 20px;
            }
            @include on(mobile) {
                margin-bottom : 0;
                margin-top: -46px;
            }
            @include on(small-device) { width : 100%; }
        }

        strong {
            @include style-as-topic;
            color     : $white_30p;
            font-size : .7em;
        }

        ul {
            list-style : none;
            padding    : 0;

            @include on(small-device) {
                display   : flex;
                flex-wrap : wrap;
            }
        }

        li {
            color         : $white_70p;
            line-height   : 1.3em;
            margin-bottom : 8px;
            padding-right : 16px;


            @include on(small-device) {
                width : 50%;
            }

            @include on(440px) {
                width : 100%;
            }
        }

        @include on(mobile) {
            margin-bottom: 64px;
        }
    }

    @include on(laptop-monitor) {
        max-width: 80ch;
    }
}
