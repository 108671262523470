@mixin animate-on-scroll {
    &.animated {
        transform  : translate(0, 0);
        opacity    : 0;
        transition : opacity .6s ease-in-out;

        &.enter {
            transform : translate(0, 0);
            opacity   : 1;
        }
    }
}