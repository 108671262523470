@import "../0-helpers/animate";
@import "../1-globals/globals";
@import "../1-globals/content-styling";
@import "../1-globals/media-query";

.page {
    /* padding    : 132px $distanceFromTheEdge; */
    padding    : 136px 106px;
    background : #060a0e;

    @include on(laptop-monitor) {
        padding : 126px 120px;
    }

    @include on(tablet-portrait) {
        padding : 126px 60px;
    }

    @include on(mobile) {
        padding: 140px 32px 0 32px;
    }

    @include on(424px) {
        padding-top: 140px;
    }

    /* .wrapper { max-width : 960px; position : relative; } */
    .wrapper { max-width : 1098px; position : relative; }
    .segment { margin-bottom: 92px; }

    @include content-styles;

}