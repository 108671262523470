@import "../1-globals/globals";

.message {
    width            : 100%;
    height           : 100%;
    position         : fixed;
    display          : flex;
    z-index          : 1000;
    padding          : 16px;
    font-sIze        : 1.2em;
    background-color : transparentize($darkBlue, 0);
    margin-bottom    : 16px;
    border-radius    : 3px;
    font-family      : $semiBoldFontStack;

    span {
        margin      : auto 0 auto $distanceFromTheEdge;
        display     : block;
        font-size   : 1.9em;
        color       : $white;
        width       : 480px;
        text-align  : left;
        line-height : 1.35em;

    }

    .button {
        white-space : normal;
        line-height : 1.5em;
    }
}