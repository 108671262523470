@import "../1-globals/globals";
@import "../1-globals/media-query";

.close-box {
    width : 42px;
    height: 42px;
    background: url(../images/close.svg) no-repeat center;
    position : fixed;
    /* top: 96px; */
    top: 134px;
    right: 28px;
    z-index : 120;
    opacity : .3;
    transition: all 260ms ease-out;
    cursor : pointer;

    @include on(tablet-portrait) {
        top: 140px;
        right: 28px;
    }

    @include on(mobile-landscape) {
        top: 110px;
        right: 18px;
    }

    @include on(mobile) {
        /* top: $distanceFromTheEdge; */
        top: 108px;
        right: 22px;
    }

    &:hover {
        opacity : 1;
    }
}

.call-to-action-btn {
    font-family: "sailec-regular";
    color: $white;
    text-align: center;
    text-decoration: none;
    background: #F08B14;
    border-radius: 50px;
    padding: 10px 24px;
    margin: 0 auto;

    @include on(mobile) {
        width: 100%;
        margin: auto 32px;
    }
}

.button-wrapper {
    display: flex;
    align-items: center;
    position: fixed;
    width: 100%;
    bottom: 5%;
    left: 50%;
    transform: translate(-50%, -5%);
    z-index: 9999;
}