@import "../1-globals/media-query";

.projects {}

.cards {
    display : flex;

    @include on(tablet-portrait) {
        display: block;
    }
}
