@import "../1-globals/globals";
@import "../1-globals/content-styling";
@import "../1-globals/form-styling";
@import "tabs";
@import "faq";

@include tabs($brightBlue);
@include style-forms($blueLabel, $lightBlue, $red);
@include style-submit-button($lightBlue);

.contact {
    padding-bottom : 32px;
    .tab-group .panes { margin-bottom : 0; }

}

.page .contact-details {
    display : flex;
    margin  : 64px 0;

    @include on(530px) {
        flex-direction : column;
        margin         : 32px 0;
    }

    .details {

        @include on(530px) {
            width         : 100%;
            margin-bottom : 32px;

            &:last-of-type {
                margin-bottom : 0;
            }
        }

    }

    .label {
        display        : block;
        font-size      : .85em;
        text-transform : uppercase;
        font-family    : $boldFontStack;
        letter-spacing : 1px;
        color          : $lightBlue;
        margin-bottom  : 8px;
    }

    .value {
        display     : block;
        font-size   : 1.2em;
        line-height : 1.45em;
        max-width   : 480px;
        color       : $white_60p;

        a {
            font-size   : 1.2em;
            color       : $white_60p;
        }

        @include on(small-device) {
            width : auto;
            font-size   : 1em;

            a {
                font-size   : 1em;
            }
        }
    }

    .phone-number {
        /* margin : 0 0 0 auto; */
        margin : 0 0 0 40%;

        .value {
            a {
                background: url(../images/facebook-icon.png) no-repeat;   
                background-size: contain;
                content: "";
                display: block;
                width: 40px;
                height: 50px;
                margin: 0 0 0 auto;
            }
            
            @include on (mobile) {
                width: 38px;
                margin: 0;
            }
        }

        @include on(mobile) {
            margin : 0 0 0 auto;

            .value {
                a {
                    width: 38px;
                }
            }
        }
    }

    .address {
        padding-right : 32px;
        max-width     : 312px;
    }

}

.social {
    border-top : 4px $white_10p solid;
    margin-bottom : 32px;
    padding-bottom : 24px;

    ul {
        padding    : 0;
        margin     : 0;
        list-style : none;
        display    : flex;
        flex-wrap: wrap;
    }

    li {
        width : 25%;
        padding : 32px 16px 24px;

        &:first-child {
            padding-left : 0;
        }

        &:last-child {
            padding-right : 0;
        }

        @include on(tablet-portrait) {
            width : 50%;
            border-bottom : 1px $white_5p solid;
            padding : 32px 32px 24px 0;
        }

        @include on(small-device) {
            width : 100%;
        }
    }

    a {
        font-size : 1.418em;
        font-family: $semiBoldFontStack;
        color: $white;
        text-decoration : none;
        display : block;
        margin-bottom : 16px;
    }
    p {
        font-size : .85em;
        line-height : 1.4em;
        margin-bottom : 0;
    }


}

.highlight {
    a {
        text-decoration: none;
        /* margin-left: 20px; */
        color: rgba(255,255,255,.6);
    }
}