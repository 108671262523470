@import "../1-globals/globals";
@import "../1-globals/media-query";

/* Header */
.header {
    z-index       : 101;
    opacity       : .4;
    /* background    : $black_5p; */
    background    : $black_90p;
    opacity       : 1;
    transition    : all 260ms ease-out;
    /* padding       : 24px $distanceFromTheEdge; */
    padding       : $distanceFromTheEdge;
    font-size     : 14px;
    display       : flex;
    border-bottom : 1px $white_5p solid;
    position      : fixed;
    width         : 100%;

    @include on(mobile-landscape) {
        padding: 22px;
    }

    @include on(mobile) {
        /* display : block; */
        display: flex;
        align-items: center;
        padding: 24px;
    }

    /* &:hover {
        opacity    : 1;
        background : $black_90p;
    } */
}

.device .header {
    opacity    : 1;
    background : $black_90p;
}

/* Header Preamble */
.frost-icon {
    /* font-family : $boldFontStack;
    color       : $white_30p;
    display     : block;
    line-height : 1.4em; */
    background: url(../images/logo.svg) no-repeat center;
    background-size: contain;
    height: 50px;
    width: 50px;

    @include on(tablet-portrait) {
        /* padding-right : 62px; */
        padding-right: 76px;
        padding-top: 12px;
        padding-bottom: 48px;
    }

    @include on(mobile-landscape) {
        padding-right: 60px;
        padding-top: 10px;
        padding-bottom: 30px;
    }

    @include on(mobile) {
        padding-right: 52px;
        padding-top: 8px;
        padding-bottom: 30px;
        height: 40px;
        width: 40px;
        /* margin-bottom : 14px; */
    }
}

/* Navigation */
.nav {
    margin     : 0 0 0 auto;
    display    : flex;
    list-style : none;
    align-items: center;

    @include on(mobile) {
        display      : block;
        padding-left : 0;
        /* padding-top  : 54px; */
    }

    li {
        margin  : 0 $distanceFromTheEdge 0 0;
        padding : 0;

        @include on(mobile) {
            display: inline-block;
        }

        &:last-child {
            margin-right : 0;
        }

        a {
            color           : $white_70p;
            font-family     : $boldFontStack;
            text-decoration : none;
            display         : block;
            line-height     : 1.4em;

            &:hover {
                color : $white;
            }

            @include on(mobile-landscape) {
                font-size: 12px;
            }
        }

        .-active {
            color : $white;
        }
    }
}