@import "../1-globals/globals";
@import "../1-globals/media-query";

.opening {
    min-height    : calc(100vh - 64px);
    display       : flex;
    border-bottom : 1px rgba(255, 255, 255, .05) solid;
    position      : relative;

    @include on(mobile) {
        min-height : calc(100vh - #{$distanceFromTheEdge});
    }

    .content {
        /* margin : auto 64px; */
        margin: auto 172px;

        @include on(laptop-monitor){
            margin: auto 112px;
        }

        @include on(mobile-landscape) {
            margin: 17% 112px;
        }

        @include on(mobile) {
            margin : auto $distanceFromTheEdge;
        }
    }

    $blueLabel : #00A3FF;

    h1 {
        margin-bottom : 8px;
        /* font-family   : $regularFontStack; */
        color         : $blueLabel;
        font-size     : 4.618em;

        span {
            color : $white_10p;
        }

        @include on(mobile) {
            font-size : 2.618em;
        }
    }

    p {
        

        font-size     : 3em;
        font-family   : sailec-regular;
        max-width     : 30ch;
        color         : $white;
        line-height   : 1.35;
        margin-top: 0;
        margin-bottom: 0;
        /* margin-bottom : 250px;
        margin-top    : 250px; */

        > span {
            color: $blueLabel;
            /* text-decoration: underline rgba(0, 163, 255, 0.4);
            text-decoration-thickness: 4px;
            text-underline-offset: 10px; */
            border-bottom: 5px solid rgba(0, 163, 255, 0.4); 

            > a {
                /* text-decoration: underline rgba(0, 163, 255, 0.4); */
                text-decoration: none;
                border-bottom: 5px solid rgba(0, 163, 255, 0.1); 
                color: $blueLabel;
                padding: 0;
                font-size: 3rem;
                margin: 0;

                @include on(mobile) {
                    /* text-decoration-thickness: 2px;
                    text-underline-offset: 4px; */
                    border-bottom: 3px solid rgba(0, 163, 255, 0.1); 
                }

            }
            
            @include on(mobile) {
                /* text-decoration-thickness: 2px;
                text-underline-offset: 4px; */
                border-bottom: 3px solid rgba(0, 163, 255, 0.4); 
            }

            &:hover {
                cursor: pointer;
            }
        }

        @include on(laptop-monitor) {
            
            /* max-width: 910px; */
            
            /* margin-bottom: 210px;
            margin-top: 210px; */
            font-size: 3rem;
            max-width: 30ch;
            line-height: 1.35;

            > span > a {
                font-size: 3rem;
            }
            
        }

        @include on(tablet-portrait) {
            font-size: 2em;

            > span > a {
                font-size: 2rem;
            }
        }

        @include on(mobile-landscape) {
            font-size: 1.25em;
            line-height: 2;

            > span {
                border-bottom: 3px solid rgba(0, 163, 255, 0.4); 
                > a {
                    font-size: 1.25em;
                    line-height: 2;
                    border-bottom: 3px solid rgba(0, 163, 255, 0.4); 
                }
            }
        }

        @include on(mobile) {
            font-size     : 1.25em;
            /* margin-bottom : $distanceFromTheEdge; */
            margin-top    : auto;
            max-width     : 320px;
            line-height   : 40px;

            > span > a {
                font-size: 1.25rem;
            }
        }
    }

    a {
        padding   : 12px 18px;
        font-size : .85em;
        margin    : 0 auto;

        @include on(mobile) {
            font-size : .618em;
        }
    }

    img {
        $width : 48px;
        width       : $width;
        position    : absolute;
        bottom      : 64px;
        left        : 50%;
        margin-left : -($width / 2);
    }

    #desktop-scroll {
        display: block;

        @include on(tablet-portrait) {
            /* bottom : $distanceFromTheEdge; */
            display: none;
        }
    }

    #mobile-scroll {
        display: none;
        
        @include on(tablet-portrait) {
            /* bottom : $distanceFromTheEdge; */
            display: block;
        }
    }
}