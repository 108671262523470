@import "globals";

@mixin animated {
    transition : all 320ms ease-out;
}

@mixin dot($dotSize) {
    content                   : '';
    display                   : inline-block;
    width                     : $dotSize;
    height                    : $dotSize;
    border-radius             : 100%;
    background                : $lightBlue;
    margin-right              : 8px;
    margin-top                : 35px;
    animation-iteration-count : infinite;
}

@mixin style-submit-button($defaultColor) {

    .button {
        @include animated;
        font-size       : 1.2em;
        text-transform  : uppercase;
        font-family     : $boldFontStack;
        letter-spacing  : 3px;
        line-height     : 1em;
        padding         : 16px 24px;
        border-radius   : 1px;
        border          : 4px $defaultColor solid;
        background      : none;
        width           : 100%;
        color           : $defaultColor;
        z-index         : 100;
        position        : relative;
        text-decoration : none;

        a {
            text-decoration: none;
            /* font-size       : 1.2em; */
            text-transform  : uppercase;
            font-family     : $boldFontStack;
            letter-spacing  : 3px;
            line-height     : 1em;
            color           : $defaultColor;
            padding         : 16px 24px;
        }

        &:focus {
            background : $defaultColor;
            color      : $darkBlue;
        }

        &:hover {
            background : $defaultColor;
            color      : $darkBlue;

            a {
                color      : $darkBlue;
            }
        }

        &.-small {
            font-size  : .7em;
            padding    : 8px 16px;
            border     : 2px solid;
            clear      : both;
            display    : block;
            max-width  : 90px;
            text-align : center;
        }
    }

    .loading-indicator {
        text-align : center;
        position   : absolute;
        width      : 100%;
        height     : 100%;
        opacity    : 0;
        z-index    : 10;
    }

    .dot {
        @include dot(8px);

        @for $dot from 0 through 2 {
            &:nth-child(#{$dot}) { animation-delay : 60ms * $dot; }
        }
    }

    .wiper {
        transform-origin : 0 50%;
        width            : 0;
        height           : 100%;
        background       : $darkBlue;
        position         : absolute;
    }

    .submit-wrapper {
        position      : relative;
        width         : 100%;
        overflow      : hidden;
        border-radius : 2px;
        border        : 1px $white_10p solid;

        // Make the elements inside animated
        &.-is-animated {

            .button { z-index : 1; }
            .loading-indicator { transition : opacity 500ms ease-out; }
            .wiper {
                z-index    : 5;
                transition : all 820ms ease-in-out;
            }
        }

        // When it is loading
        &.-is-loading {

            .loading-indicator {
                transition-delay : 500ms;
                opacity          : 1;
            }

            .wiper { width : 100%; }

        }

        // when the loading is done
        &.-done {

            .loading-indicator { opacity : 0; }

            .wiper {
                transform-origin : 100% 50%;
                transform        : translate(100%, 0);
                transition-delay : 800ms;
            }

        }

    }

}

@mixin style-forms($defaultColor, $selectColor, $errorColor) {

    .field-group {
        display : flex;

        @include on(mobile) {
            display : block;
        }
    }

    textarea, input {
        font-family : $regularFontStack;
        font-size   : 1.2em;
        line-height : 1.4em;
        color       : $white_85p;
        outline     : none;
    }

    .field {
        @include animated;
        border-bottom : 2px $white_10p solid;
        flex          : 1 0;
        margin        : 0 16px 16px 0;

        @include on(mobile) {
            margin-right : 0;
        }

        label {
            display     : block;
            font-family : $semiBoldFontStack;
            font-size   : .85em;
            color       : $defaultColor;
            @include animated;

            &:after {
                color: #e32;
                content: ' *';
                display:inline;
            }
        }

        input {
            background : none;
            border     : 0;
            width      : 100%;
            padding    : 12px 0;

            // Prevent the page from having a different
            // color when auto filled
            // only for chrome
            &:-webkit-autofill {
                -webkit-box-shadow      : 0 0 0 1000px $darkBlue inset;
                -webkit-text-fill-color : $white_85p;
            }
        }

        textarea {
            width      : 100%;
            background : none;
            border     : 0;
            height     : 120px;
        }

        &.focused {
            border-bottom : 2px $selectColor solid;

            label { color : $selectColor; }
        }

        &.error {
            border-bottom : 2px $errorColor solid;

            label { color : $errorColor; }
        }

        &:last-of-type {
            margin-right : 0;
        }

        /* ----------------------------------------------------------------- */

        .services-dropdown-wrapper {
            position: relative;
            user-select: none;
            width: 100%;
       }

       .services-dropdown-wrapper.error {
            .services-dropdown {
                .services-dropdown-trigger {
                    color: #C10A0A;
                }
            }
        }
        .services-dropdown {
            position: relative;
            display: flex;
            flex-direction: column;
       }
        .services-dropdown-trigger {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 22px;
            font-size: 20px;
            font-weight: 300;
            color: $white;
            height: 60px;
            line-height: 60px;
            cursor: pointer;
       }
        .services-options {
            position: absolute;
            display: block;
            top: 100%;
            left: 0;
            right: 0;
            border: 2px solid #394a6d;
            border-top: 0;
            transition: all 0.5s;
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            background: #060a0e;
            z-index: 2;
       }
        .services-dropdown.open .services-options {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            z-index: 500;
       }
        .services-option {
            position: relative;
            display: block;
            padding: 0 22px 0 22px;
            font-size: 22px;
            font-weight: 300;
            color: $white;
            line-height: 60px;
            cursor: pointer;
            transition: all 0.5s;

            &:hover {
                cursor: pointer;
                background-color: #b2b2b2;
            }
       }
       .hidden {
            display: none;
        }
        .services-option.selected {
            color: #ffffff;
            background-color: #305c91;
       }

       .dropdown-arrow {
            position: relative;
            height: 15px;
            width: 15px;

            &:before,
            &:after {
                content: "";
                position: absolute;
                bottom: 0px;
                width: 0.15rem;
                height: 100%;
            }

            &:before {
                left: -5px;
                transform: rotate(-45deg);
                background-color: #394a6d;

            }

            &:after {
                left: 5px;
                transform: rotate(45deg);
                background-color: #394a6d;
            }
        }
        .open {
            .services-dropdown-trigger {
                .dropdown-arrow {
                    &:before {
                        left: -5px;
                        transform: rotate(45deg);
                    }
                    
                    &:after {
                        left: 5px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }

       /* ----------------------------------------------------------------- */

    }

}


