@font-face {
    font-family: 'proxima-semibold';
    src: url('../fonts/proximanova-semibold-webfont.eot');
    src: url('../fonts/proximanova-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-semibold-webfont.woff') format('woff'),
    url('../fonts/proximanova-semibold-webfont.ttf') format('truetype'),
    url('../fonts/proximanova-semibold-webfont.svg#ProximaNovaSemiBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-regular';
    src: url('../fonts/proximanova-regular-webfont.eot');
    src: url('../fonts/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-regular-webfont.woff') format('woff'),
    url('../fonts/proximanova-regular-webfont.ttf') format('truetype'),
    url('../fonts/proximanova-regular-webfont.svg#ProximaRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-bold';
    src: url('../fonts/proximanova-bold-webfont.eot');
    src: url('../fonts/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/proximanova-bold-webfont.woff') format('woff'),
    url('../fonts/proximanova-bold-webfont.ttf') format('truetype'),
    url('../fonts/proximanova-bold-webfont.svg#ProximaBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'sailec-regular';
    src: url('../fonts/SailecRegular.eot');
    src: url('../fonts/SailecRegular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SailecRegular.woff') format('woff'),
    url('../fonts/SailecRegular.ttf') format('truetype'),
    url('../fonts/SailecRegular.svg#ProximaBold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'playfair-display';
    src: url('../fonts/PlayfairDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}