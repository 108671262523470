@import './globals';
@import "./media-query";

/*
    Add a top dashed style on heading
*/
@mixin top-dashed {
    content       : '';
    width         : 48px;
    height        : 2px;
    background    : $white_10p;
    display       : block;
    margin-bottom : 16px;
}

/*
    Use for topic heading
*/
@mixin style-as-topic {
    font-size      : .85em;
    text-transform : uppercase;
    font-family    : $boldFontStack;
    color          : $lightBlue;
    display        : block;
    margin         : 1.2em 0;
    letter-spacing : 1px;
}

@mixin content-styles {
    /* Type settings */

    h1 {
        font-size      : 2.618em;
        font-family    : $boldFontStack;
        text-transform : uppercase;

        @include on(small-device) {
            font-size : 2em;
        }
    }

    h2 {
        font-size      : 1.4em;
        letter-spacing : 1px;
        font-family    : $boldFontStack;
        text-transform : uppercase;
        margin-bottom  : 1.618em;

        &:before {
            @include top-dashed;
        }

        + p {
            @extend .highlight;
        }
    }

    h3 {
        @include style-as-topic;
        margin-top  : 2.618em;
        line-height : 1.6em;
        display     : block;
        padding     : $distanceFromTheEdge 0 0;

        &:first-of-type { border-top : 0; padding-top : 0; }
    }

    h4 {
        font-size   : 1em;
        font-family : $semiBoldFontStack;
    }

    p {
        font-size : 1em;
        color     : $white_50p;

        &.highlight {
            color         : $white_80p;
            font-size     : 1.4em;
            max-width     : 80%;
            line-height   : 1.4em;
            margin-bottom : 1.618em;

            @include on(mobile) {
                font-size : 1.2em;
                max-width : inherit;
            }
        }
    }

}

@mixin tab-group-content-images {
    /* > .content-playbook { 
        &:before {
            background: url(../images/icon-playbook.svg) no-repeat;
            background-size: contain;
            opacity: 0.8;
        }
    }

    > .content-infastructure {
        &:before {
            background: url(../images/icon-infastructure.svg) no-repeat;
            background-size: contain;
            opacity: 0.8;
        }
    }

    > .content-blueprints {
        &:before {
            background: url(../images/icon-blueprints.svg) no-repeat;
            background-size: contain;
            opacity: 0.8;
        }
    }

    > .content-audits {
        &:before {
            background: url(../images/icon-audits.svg) no-repeat;
            background-size: contain;
            opacity: 0.8;
        }
    } */

    > li {
        /* &:before {
            content: "";
            display: block;
            width: 80px;
            height: 90px;
            margin: 0 auto;

            @include on(tablet-portrait) {
                margin-bottom: 26px;
            }

            @include on(mobile-landscape) {
                margin-bottom: 0;
            }

            @include on(mobile) {
                margin: 0;
            }
        } */

        @include on(mobile-landscape) {
            margin-bottom: 20px;
        }
    }
}

@mixin tab-content-group-styles {
    p {
        font-family: "sailec-regular";
        font-style: normal;
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 1.5;
        /* color: rgba(255, 255, 255, 0.8); */
        color: $white;
        opacity: 0.8;
        max-width: 40ch;

        @include on(laptop-monitor) {
            font-size: 1rem;
        }

        @include on(tablet-portrait) {
            font-size: 1rem;
            margin-bottom: 100px;
            max-width: 24ch;
        }

        @include on(mobile-landscape) {
            font-size: 0.75rem;
            line-height: 1.75;
            max-width: 100%;
        }

        @include on(mobile) {
            /* font-size: 1.25rem; */
            font-size: .875rem;
            line-height: 1.7;
            margin-bottom: 2em;
            max-width: 100%;
        }
    }

    div {
        width: 100%;
        max-width: 440px;
        
        strong {
            font-family: "sailec-regular";
            font-style: normal;
            font-weight: bold;
            font-size: 0.75rem;
            line-height: 1.5;
            color: #FFFFFF;

            @include on(mobile) {
                font-size: 0.875rem;
            }
        }

        ul {
            font-family: "sailec-regular";
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            /* line-height: 1.5; */
            line-height: 26px;
            max-width: 40ch;
            /* margin-bottom: 72px; */
            margin-bottom: 0px;
            padding-bottom: 0;

            li {
                line-height: 22px;
                
                @include on(laptop-monitor) {
                    /* line-height: 1.2em; */
                    line-height: 20px;
                }
                @include on(tablet-portrait) {
                    width: 100%;
                }

                @include on(mobile-landscape) {
                    line-height: 16px;
                }
            }

            @include on(laptop-monitor) {
                font-size: 0.875rem;
                /* margin-bottom: 10px; */

                @supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
                    /* margin-bottom: 10px; */
                }
    
                @media not all and (min-resolution:.001dpcm) { 
                    /* margin-bottom: 10px; */
                }
            }

            @include on(1070px) {
                /* margin-bottom: 62px; */
            }

            @include on(tablet-portrait) {
                max-width: 298px;
                font-size: 1rem;
                /* margin-bottom: 66px; */
            }

            @include on(mobile-landscape) {
                font-size: 0.75rem;
                flex-wrap: wrap;
                /* margin-bottom: 78px; */
            }

            @include on(mobile) {
                font-size: 0.875rem;
                margin-bottom: 0;
                max-width: 100%;
            }
        }

        /* > ul:first-of-type {
            margin-bottom: 196px;

            @include on(laptop-monitor) {
                margin-bottom: 110px;

                @supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
                    margin-bottom: 110px;
                }
    
                @media not all and (min-resolution:.001dpcm) { 
                    margin-bottom: 110px;
                }
            }
            

            @include on(1070px) {
                margin-bottom: 138px;
            }

            @include on(tablet-portrait) {
                margin-bottom: 234px;
                margin-bottom: 214px;
            }

            @include on(mobile-landscape) {
                margin-bottom: 184px;
            }

            @include on(mobile) {
                margin-bottom: 0;
            }
        } */

        > br {
            margin-bottom: 4px;
            content: "";
            display: inline-flex;

            &:after {
                content: "";
                margin-bottom: 60px;
            }
        }

        > .front-end-br,
          .copy-content-br {
            margin-bottom: -32px;
            content: "";
            display: inline-flex;

            &:after {
                content: "";
                margin-bottom: 60px;
            }
        }

        @-moz-document url-prefix() {

            > br,
            .front-end-br,
            .copy-content-br {
                margin-bottom: 18px;
                content: "";
                display: inline-flex;
            }
        }

        @supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
            > br {
                margin-bottom: 4px;
                content: "";
                display: inline-flex;

                &:after {
                    content: "";
                    margin-bottom: 60px;
                }
            }

            > .front-end-br,
              .copy-content-br {
                margin-bottom: -32px;
                content: "";
                display: inline-flex;

                &:after {
                    content: "";
                    margin-bottom: 60px;
                }
            }
        }

        @media not all and (min-resolution:.001dpcm) { 
            > br {
                margin-bottom: 4px;
                content: "";
                display: inline-flex;

                &:after {
                    content: "";
                    margin-bottom: 60px;
                }
            }

            > .front-end-br,
              .copy-content-br {
                margin-bottom: -32px;
                content: "";
                display: inline-flex;

                &:after {
                    content: "";
                    margin-bottom: 60px;
                }
            }
        }

        @include on(laptop-monitor) {
            > br {
                /* margin-bottom: -12px; */
                margin-bottom: -32px;
                content: "";
                display: inline-flex;
    
                &:after {
                    content: "";
                    margin-bottom: 60px;
                }
            }
    
            > .front-end-br,
              .copy-content-br {
                margin-bottom: -36px;
                content: "";
                display: inline-flex;
    
                &:after {
                    content: "";
                    margin-bottom: 60px;
                }
            }
    
            @supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
                > br {
                    margin-bottom: -12px;
                    content: "";
                    display: inline-flex;
    
                    &:after {
                        content: "";
                        margin-bottom: 60px;
                    }
                }
    
                > .front-end-br,
                  .copy-content-br {
                    margin-bottom: -32px;
                    content: "";
                    display: inline-flex;
    
                    &:after {
                        content: "";
                        margin-bottom: 60px;
                    }
                }
            }
    
            @media not all and (min-resolution:.001dpcm) { 
                > br {
                    margin-bottom: -12px;
                    content: "";
                    display: inline-flex;
    
                    &:after {
                        content: "";
                        margin-bottom: 60px;
                    }
                }
    
                > .front-end-br,
                  .copy-content-br {
                    margin-bottom: -32px;
                    content: "";
                    display: inline-flex;
    
                    &:after {
                        content: "";
                        margin-bottom: 60px;
                    }
                }
            }

            @-moz-document url-prefix() {

                > br,
                .front-end-br,
                .copy-content-br {
                    margin-bottom: 18px;
                    content: "";
                    display: inline-flex;
                }
            }
        }

        /* @include on(1070px) {
            max-width: 300px;

            > br {
                margin-bottom: -32px;
                content: "";
                display: inline-flex;

                &:after {
                    content: "";
                    margin-bottom: 60px;
                }
            }
        } */

        @include on(tablet-portrait) {
            max-width: 300px;

            > br {
                margin-bottom: -14px;
                content: "";
                display: inline-flex;

                &:after {
                    content: "";
                    margin-bottom: 60px;
                }
            }

            @-moz-document url-prefix() {

                > br {
                    margin-bottom: 38px;
                    content: "";
                    display: inline-flex;
                }                
            }
        }

        @include on(mobile) {
            max-width: 100%;

            > br,
            .front-end-br,
            .copy-content-br {
                display: none;

                &:after {
                    display: none;
                }
            }

            > strong {
                margin-bottom: 1em;
            }
        }
    }
}


