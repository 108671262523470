@import "globals";
@import "../fonts/fonts";

html { font-size : 1em; }

body, html {
    font-size: $baseFontSize;
    font-family : $regularFontStack;
    line-height: 1.618em;

    font-variant-ligatures: common-ligatures;
    text-rendering: optimizeLegibility;

    font-feature-settings: "kern"; /* standard */
    font-kerning: normal; /* Safari 7+, Firefox 24+, Chrome 33(?)+, Opera 21+*/

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background  : $background;
}

p {
    font-size: 1em;
    line-height: 1.618em;
    margin: 0 0 1.4em;
}

h1, h2, h3, h4 {
    margin      : 1.414em 0 0.5em;
    font-weight : inherit;
    line-height : 1.2;
}

h1 {
    margin-top : 0;
    font-size  : 6.854em;
}

h2 { font-size : 4.236em; }

h3 { font-size : 2.618em; }

h4 { font-size : 1.618em; }

small, .font_small { font-size : 0.618em; }