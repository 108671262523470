@import "../1-globals/globals";
@import "../1-globals/media-query";

/**
 * Apply an inset border using box shadow
 */
@function inset-border($sizeX, $sizeY, $color) {
    @return inset $sizeX $sizeY 0 $color;
}

@mixin tabs($selectedColor) {
    $borderRight         : inset-border(-1px, 0, $white_5p);
    $borderBottom        : inset-border(0, -1px, $white_5p);
    $hoveredBorderBottom : inset-border(0, -4px, $white_45p);
    $activeBorderBottom  : inset-border(0, -4px, $selectedColor);

    .tab-group {

        .tabs {
            display       : flex;
            padding       : 0;
            margin        : 0;
            border-bottom : 1px $white_10p solid;

            @include on(small-device) {
                flex-direction: column;
            }
        }

        /*================================================
            Individual Tab
         ================================================*/
        .tab {
            list-style  : none;
            flex        : 1 0;
            padding     : 16px 22px;
            font-family : $semiBoldFontStack;
            font-size   : 1.2em;
            color       : $white_25p;
            cursor      : pointer;
            box-shadow  : $borderRight, $borderBottom;

            @include on(small-device) {
                font-size : 1em;
                padding   : 16px 0;
            }

            /* Hover state */
            &:hover {
                color      : $white_45p;
                box-shadow : $borderRight, $hoveredBorderBottom;
            }

            /* First tab element */
            &:first-child {
                padding-left : 0;
            }

            /* Last tab element */
            &:last-of-type {
                box-shadow    : $borderBottom;
                margin-right  : 0;
                padding-right : 0;

                &:hover {
                    box-shadow : $hoveredBorderBottom;
                }
            }
        }

        /* Active state */
        .tab.-active {
            box-shadow : $borderRight, $activeBorderBottom;
            color      : $selectedColor;

            &:hover {
                color      : $selectedColor;
                box-shadow : $borderRight, $activeBorderBottom;
            }

            &:last-of-type {
                color      : $selectedColor;
                box-shadow : $activeBorderBottom;
            }
        }

        .panes {
            margin-bottom : 8.618em;
        }

        /*================================================
            Individual Pane
         ================================================*/
        .pane {
            width   : 100%;
            padding : 32px 0 0 0;
        }
    }
}