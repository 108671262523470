@import "../1-globals/globals";
@import "../1-globals/content-styling";

/* FAQ Toggle button */

.faq-toggle {
    $faded : transparentize($lightBlue, .9);

    border        : 4px $lightBlue solid;
    color         : $lightBlue;
    padding       : 10px 16px;
    border-radius : 2px;
    margin-bottom : 1.618em;
    display       : inline-block;
    cursor        : pointer;

    &:hover {
        color      : $darkBlue;
        background : $lightBlue;
    }
}

/* FAQ Content styling */

.faq {
    border-left   : 4px $white_10p solid;
    padding-left  : $distanceFromTheEdge;
    margin-bottom : 2.618em;

    h3 {
        margin-top  : 0;
        padding-top : 0;

        &:not(:first-of-type):before {
            @include top-dashed;
            margin-top : 2.618em;
        }
    }

    h4 {
        font-size : .9em;
    }

    p {
        font-size     : 1em;
        max-width     : 640px;
        line-height   : 1.4em;
        margin-bottom : 1.618em;

        strong {
            color   : $white_85p;
            display : inline-block;
        }
    }
}